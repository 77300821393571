<template>
  <v-footer
    class="py-4 popolvuh-footer"
    height="auto"
    dark
  >
    <v-container class="mx-auto">
      <v-row
        align="center"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-img
            :src="require('@/assets/logos/logo_popol_vuh.png')"
            contain
            height="150"
            @click="$vuetify.goTo(0)"
            class="footer-image"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <a
            href="/pdf/privacy_notice.pdf"
            target="_blank"
          >
            {{ content.avisoPrivacidad }}
          </a>
          <v-spacer />
          <a
            href="/pdf/terms_and_conditions_of_use.pdf"
            target="_blank"
          >
            {{ content.terminosCondiciones }}
          </a>
          <v-spacer />
          <a
            href="mailto:contacto@mezcalpopolvuh.com.mx"
          >
             contacto@mezcalpopolvuh.com.mx
          </a>
          <v-spacer />
          {{ content.derechos }}
          <v-spacer />
          <span class="recaptha-terms">
          This site is protected by reCAPTCHA <br> and the Google
          <a class="recaptcha-anchor" href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a class="recaptcha-anchor" href="https://policies.google.com/terms">Terms of Service</a> apply.
          </span>          
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <base-btn
            v-for="(item, i) in items"
            :key="i"
            :href="item.href"
            class="ml-0 mr-3 footer-btn"
            color="primary"
            circle
            target="_blank"
          >
            <v-icon v-text="item.icon" />
          </base-btn>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',
    data: () => ({
      items: [
        {
          href: 'https://www.facebook.com/mezcal.popolvuh/',
          icon: 'mdi-facebook',
        },
        {
          href: 'https://linkedin.com/in/mezcal-popolvuh',
          icon: 'mdi-linkedin',
        },
        {
          href: 'https://www.instagram.com/mezcalpopolvuh/',
          icon: 'mdi-instagram',
        },
        {
          href: 'https://www.youtube.com/channel/UCMzUHMVoF-YGYhH6Mon_oNg',
          icon: 'mdi-youtube',
        },
      ],
    }),
    computed: {
      content () {
        return this.$store.state.webpagecontent[0][this.$store.state.lang].page
      },
    },
  }
</script>

<style scoped>
  .popolvuh-footer {
    background-color: #3F5465 !important;
  }
  .footer-image {
    cursor: pointer;
  }
  .recaptha-terms {
    font-size:10px;
    color: #6888a1;
  }
  .recaptcha-anchor {
    color: #88a1b5;
  }
</style>
